<template>
  <div class="payBank">
    <!-- 导航栏 -->
    <van-nav-bar
      title="Nạp tiền"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Nạp tiền</span
        >
      </template>
      <template #right v-if="!verConfig.useNewToRecharge">
        <button @click="$router.push('/user/pay/record')">Lịch sử</button>
        <button @click="handlerService">Dịch vụ khách hàng</button>
      </template>
    </van-nav-bar>

    <div class="pannel">
      <!-- language-ch 充值金额：₫ ,更新-->
      <div class="money" v-if="!verConfig.useNewToRecharge">
        <span>Số tiền nạp： ₫</span>
        <span class="red">{{ payInfo.money }}</span>
      </div>

      <!-- 其他支付方式 -->
      <div class="other_pay" v-if="payInfo.bank !== 'bank-BANK'">
        <img :src="payInfo.url" v-if="payInfo.url" />
        <img
          v-else
          src="require('@/assets/images/user_facephoto/facePhoto.png')"
        />
      </div>

      <!-- language-ch Tên chủ thẻ -->
      <div class="information" v-else>
        <van-cell-group>
          <van-field label="Tên chủ thẻ" :value="payInfo.setting.open" readonly>
            <template #button>
              <van-button
                size="mini"
                plain
                type="danger"
                @click="copyText(payInfo.setting.open)"
                >sao chép</van-button
              >
            </template>
          </van-field>
          <van-field
            label="Tên tài khoản:"
            :value="payInfo.setting.name"
            readonly
          >
            <template #button>
              <van-button
                size="mini"
                plain
                type="danger"
                @click="copyText(payInfo.setting.name)"
                >sao chép</van-button
              >
            </template>
          </van-field>
          <van-field
            label="Số tài khoản"
            :value="payInfo.setting.card"
            readonly
          >
            <template #button>
              <van-button
                size="mini"
                plain
                type="danger"
                @click="copyText(payInfo.setting.card)"
                >sao chép</van-button
              >
            </template>
          </van-field>
        </van-cell-group>
      </div>
    </div>

    <!-- language-ch 充值步骤： -->
    <div class="step">
      <h5>Lưu ý khi nạp tiền:</h5>
      <p>{{ step }}</p>
    </div>

    <!-- 功能按钮区域 -->
    <div class="footer" v-if="!verConfig.useNewToRecharge">
      <van-button type="info" @click="localClick('Submit')"
        >Gửi đơn nạp tiền</van-button
      >
    </div>

    <!-- 弹出层 -->
    <van-popup v-model="showPopup" closeable round :style="{ height: '35%' }">
      <div class="popup">
        <van-divider :style="{ color: '#000', borderColor: '#000' }" />
        <div class="text">
          You have to {{ date | formatTime('YYYY-MM-DD HH:mm:ss') }} time to ₫
          {{ payInfo.money }} please fill in the top up
        </div>
        <div class="float-money">
          <span>Actual recharge amount</span>
          <input type="number" v-model="inputValue" />
        </div>
        <div class="float-foot-box">
          <van-button color="#E4F8FF" @click="localClick('Customer')"
            >Contact Customer</van-button
          >
          <van-button color="#95E3FD" @click="localClick('Submit')"
            >Submit Order</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
  name: '',
  components: {},
  data () {
    return {
      payInfo: {},
      step: '',
      showPopup: false,
      date: Date.now(),
      inputValue: '',
      id: '',
      account: ''
    }
  },
  mounted () {
    this.payInfo = JSON.parse(this.$route.query.payInfo)
    this.step = this.$route.query.step
    this.id = this.$route.query.id
    this.account = this.$route.query.account
    console.log(this.$route.query)
  },
  created () {},
  methods: {
    handlerService () {
      this.$toast({
        message: 'giữ nguyên',
        icon: 'smile-o'
      })
    },

    copyText (data) {
      const url = data
      // eslint-disable-next-line no-irregular-whitespace
      var input = document.createElement('input') // 直接构建input
      input.value = url // 设置内容
      // eslint-disable-next-line no-irregular-whitespace
      document.body.appendChild(input) // 添加临时实例
      // eslint-disable-next-line no-irregular-whitespace
      input.select() // 选择实例内容
      // eslint-disable-next-line no-irregular-whitespace
      document.execCommand('Copy') // 执行复制
      this.$toast({
        message: 'replicated',
        position: 'bottom'
      })
      document.body.removeChild(input) // 删除临时实例
    },

    // 支付提交或跳转服务
    async localClick (act) {
      console.log(this.payInfo.money)
      this.inputValue = this.payInfo.money
      if (act === 'Customer') {
        this.$toast({
          message: 'Coming soon',
          icon: 'like-o'
        })
      } else {
        if (this.inputValue.trim().length > 0) {
          const { data } = await request({
            method: 'get',
            url: 'pay/submit',
            params: {
              id: this.id,
              money: this.inputValue,
              account: this.account,
              isbefore: 0
            }
          })
          if (data.ret === 3) {
            this.$router.back()
          }
          this.$toast('Đơn nạp tiền gửi thành công')
          return
        }
        this.$toast('Thất bại')
      }
    }
  },
  computed: {}
}
</script>

<style lang="less" scoped>
.payBank {
  position: relative;
  height: 100%;
  font-family: 'PingFang-Regular';
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    button {
      background-color: #ff0000;
      border: none;
      color: #fff;
      font-size: 20px;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .pannel {
    margin: 20px;
    padding: 25px;
    background-color: #fff;
    font-size: 28px;

    .other_pay {
      height: 500px;
      width: 500px;
      margin-left: 80px;
      img {
        height: 500px;
        width: 500px;
      }
    }
    .money {
      margin-bottom: 15px;
      .red {
        font-size: 36px;
        color: #ff0000;
      }
    }
    .van-field {
      .van-button {
        height: 40px;
        margin-bottom: 5px;
      }
    }
    [class*=van-hairline]::after{
      border: none;
    }
    .van-cell {
      padding: 20px 0;
      font-size: 24px;
      /deep/ .van-field__label {
        width: 200px;
      }
      /deep/ .van-field__control {
        color: #000;
        font-size: 24px;
      }
    }
  }
  .step {
    margin: 20px;
    font-size: 2px;
    color: #646566;
    h5 {
      margin: 25px 0 15px 0;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    .van-button {
      width: 750px;
    }
  }

  .van-popup {
    .popup {
      box-sizing: border-box;
      padding: 90px 0;
      height: 100%;
      width: 600px;
      background-image: url('../../assets/images/pay/completebg.png');
      background-size: 600px 390px;
      font-size: 28px;
      /deep/ .van-popup__close-icon {
        color: red !important;
      }
      .text {
        margin: 0 68px;
      }
      .float-money {
        margin: 20px 68px;
        input {
          width: 140px;
          border: none;
          font-size: 34px;
          color: #ff0000;
          text-align: center;
          background-color: transparent;
          border-bottom: 1px solid #ff0000;
        }
      }

      .float-foot-box {
        position: absolute;
        bottom: 0;
        left: 0;
        .van-button {
          width: 300px;
          /deep/ .van-button__text {
            color: #000;
          }
        }
      }
    }
  }
}
</style>
